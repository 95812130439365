.card-body-event {
    padding: 6px !important;
}

.list-group-item {
    padding: 0.3rem 1.25rem;
}

.more-info {
    color: #fff;
}

.more-info:hover {
    color: #fff;
    text-decoration: none;
}

.value-info {
    color: #000;
}

.value-info:hover {
    color: #000;
    text-decoration: none;
}

.status-value {
    font-size: 2rem;
    font-weight: 700;
}

.status-text {
    font-size: 1.25rem;
}

@media (min-width: 992px) {
    .status-event {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 10px 0 0 0 !important;
    }
}

.status-event {
    padding-top: 10px;
}

.head-detail {
    float: "left";
    font-size: 1.25rem;
    font-weight: 500;
    padding-right: 15px;
}

.link-detail {
    float: "left";
}

.font-sm {
    font-size: 0.8rem;
}